// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("jquery")
require("popper.js")
require("bootstrap")
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("maps")
require("magnific")
require('cleave.js');
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"
import $ from 'jquery'

window.jQuery = $

var Navbar = (function() {

	// Variables
	// =========

	var $navbar = 			$('.navbar:not(.static)');
	var $navbarLink = 		$('.nav-link[href^="#"]'); // anchor link
	var $navbarCollapse = 	$('.navbar-collapse');
	var $window = 			$(window);

	// Methods
	// =======

	function makeNavbarDark() {
		$navbar.removeClass('navbar-light').addClass('navbar-dark');
	}
	function makeNavbarLight() {
		$navbar.removeClass('navbar-dark').addClass('navbar-light');
	}
	function toggleNavbarClass() {
		var scrollTop = $window.scrollTop();

		if ( scrollTop > 5 ) {
			makeNavbarDark();
		} else {
			makeNavbarLight();
		}
	}

	// Events
	// ======

	// Toggle navbar class on document ready event
	toggleNavbarClass();

	// Window events
	$window.on({
		'scroll': function() {

			// Toggle navbar class on window scroll
			toggleNavbarClass();
		},
		'activate.bs.scrollspy': function() {

			// Navbar active link fix
			$navbarLink.filter('.active').focus();
		}
	});

	// Toggle navbar class on collapse
	$navbarCollapse.on({
		'show.bs.collapse': function() {
			makeNavbarDark();
		},
		'hidden.bs.collapse': function() {
			var scrollTop = $window.scrollTop();

			if (scrollTop == 0) {
				makeNavbarLight();
			}
		}
	});

	// Collapse navbar on an anchor link click
	// $navbarLink.on('click', function() {
	// 	$navbarCollapse.collapse('hide');
	// });

});

$(document).on("shown.bs.modal", function() {
	$("#searchQuery").focus()
})

$(document).on("turbolinks:load", function() {
	$('[data-toggle="popover"]').popover()
  $('[data-toggle="tooltip"]').tooltip()
  Navbar()

	$("#free-shipping-modal").modal("show")

  $('[data-parallax="scroll"]').each(function() {
    var src = $(this).data("image-src")
    $(this).parallax({imageSrc: src})
  })

	var altText = $('[data-parallax="scroll"]').data("alt-text")
	$(".parallax-slider").attr("alt", altText)

	document.querySelectorAll("input[type='tel']").forEach(function(el) {
	  new Cleave(el, {
		  numericOnly: true,
		  delimiter: '-',
		  blocks: [3, 3, 4]
		});
	});
})

$(document).on("click", "[data-behavior='toggle']", function(e) {
	e.preventDefault()
	var target = $(this).data("target")
	var className = $(this).data("class")
	$(target).toggleClass(className)
})
