import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "links", "template", "items" ]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
  }

  add_association(event) {
    event.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.itemsTarget.insertAdjacentHTML('beforeend', content)
  }

  remove_association(event) {
    event.preventDefault()

    if (this.itemsTarget.getElementsByClassName("nested-fields").length > 1) {
      let wrapper = event.target.closest("." + this.wrapperClass)
      wrapper.remove()
    }
  }
}
