import $ from 'jquery'

require("./jquery.magnific-popup.min")

$(document).on("turbolinks:load", function() {
  if ($('.image-link').length > 0) {
    $('.image-link').magnificPopup({
      type: 'image'
    })
  }
})
