import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["custom"]

  reveal(event) {
    let val = event.target.value

    if (val == "Other") {
      this.customTarget.classList.remove("d-none")
    } else {
      this.customTarget.classList.add("d-none")
    }
  }
}
