import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "event" ]

  connect() {
  }

  decrement(event) {
    if (this.quantity == 1) { return }
    this.inputTarget.value = this.quantity - 1
    this.saveQuantity()
  }

  increment(event) {
    this.inputTarget.value = parseInt(this.quantity) + 1
    this.saveQuantity()
  }

  update(event) {
    if (this.quantity < 1) {
      this.inputTarget.value = 1
    } else {
      this.saveQuantity()
    }
  }

  saveEvent() {
    if (this.eventTarget.value != "") {
      var data = {
        line_item: {
          event_name: this.eventTarget.value
        }
      }

      fetch(this.data.get("url"), {
        method: 'PATCH',
        headers: {
          'Content-Type': "application/json"
        },
        body: JSON.stringify(data)
      })
    }
  }

  saveQuantity() {
    var data = {
      line_item: {
        quantity: this.quantity
      }
    }

    fetch(this.data.get("url"), {
      method: 'PATCH',
      headers: {
        'Content-Type': "application/json"
      },
      body: JSON.stringify(data)
    })
  }

  get quantity() {
    return this.inputTarget.value
  }
}
