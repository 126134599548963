import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["referral", "modal", "form", "submit", "deliveryInstructions"]

  accept(event) {
    let input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", "trade_account_signup[terms_and_conditions]");
    input.setAttribute("value", "1");

    let button = document.getElementById("terms-button")
    button.classList.add('btn-success')
    button.classList.remove('btn-primary')
    button.innerText = "Accepted Terms and Conditions"
    button.disabled = true

    this.formTarget.appendChild(input);

    $("#termsModal").modal("hide")

    this.submitTarget.classList.remove("d-none")
  }

  reveal(event) {
    let val = event.target.value

    if (val == "Referral") {
      this.referralTarget.classList.remove("d-none")
    } else {
      this.referralTarget.classList.add("d-none")
    }
  }

  revealDeliveryInstructions(event) {
    if (event.target.checked) {
      this.deliveryInstructionsTarget.classList.remove("d-none")
    } else {
      this.deliveryInstructionsTarget.classList.add("d-none")
    }
  }
}
